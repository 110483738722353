@import '../../styles/variables';

.banner {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: #545454;
  z-index: 1;
  img {
    width: 200px;
    height: auto;
    opacity: 0.96;
    border-radius: 0.3rem;
    transition: opacity 0.5s $cubic-out;
    &:hover {
      opacity: 1;
    }
  }
}
