@use 'variables' as *;

// @import 'mixins';
body {
  // overscroll-behavior-y: contain;
  overscroll-behavior-y: none;

  margin: 0;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
}
.main {
  margin-top: 8rem;
}
.container {
  max-width: $layout-width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  @media #{$max-s} {
    // padding-left: 0.5rem;
    // padding-right: 0.5rem;
  }
}
a:hover {
  color: $blue;
  transition: color 0.4s $cubic-out;
}
.svg-defs {
  display: none;
}
.padLR-l {
  padding-left: 6.25rem;
  padding-right: 6.25rem;
  @media #{$max-l} {
    padding-left: 4.25rem;
    padding-right: 4.25rem;
  }
  @media #{$max-m} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media #{$max-s} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media #{$max-xs} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.padLR {
  padding-left: 5.313rem;
  padding-right: 5.313rem;
  @media #{$max-m} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media #{$max-xs} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.padL {
  padding-left: 5.313rem;
  @media #{$max-m} {
    padding-left: 3rem;
  }
  @media #{$max-xs} {
    padding-left: 1rem;
  }
}
.padR {
  padding-right: 5.313rem;
  @media #{$max-m} {
    padding-right: 3rem;
  }
  @media #{$max-xs} {
    padding-right: 1rem;
  }
}
.padLR-sm {
  padding-left: 4.188rem;
  padding-right: 4.188rem;
  @media #{$max-xs} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.padL-sm {
  padding-left: 4.188rem;
  @media #{$max-s} {
    padding-left: 3rem;
  }
  @media #{$max-xs} {
    padding-left: 0rem;
  }
}
.padR-sm {
  padding-right: 4.188rem;
}

.isDark {
  background-color: $black;
  color: $white;
}

.page {
  background-color: $light-grey4;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .container {
    margin: 0 auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .inner {
      max-width: 80rem;
    }
    h1 {
      color: $dark-grey2;
      font-size: 3rem;
      &.page-header-title {
        color: $white;
        font-weight: $bold;
      }
      @media #{$max-m2} {
        font-size: 2rem;
      }
      @media #{$max-s} {
        font-size: 1.8rem;
      }
      @media #{$max-xs} {
        font-size: 1.5rem;
      }
    }
    .text {
      padding: 2rem 0 3rem;
      font-weight: $light;
      font-size: 1.25rem;
      line-height: 1.3;

      p {
        padding-bottom: 1.2rem;
      }

      li {
        padding-bottom: 1rem;
        list-style: inside;
      }
      h2 {
        font-weight: $bold;
        font-size: 1.4rem;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
      }
      h3 {
        font-size: 1.4rem;
        padding-bottom: 0.5rem;
        font-weight: $medium;
      }

      strong {
        font-weight: $medium;
      }
      @media #{$max-m2} {
        font-size: 1.1rem;
      }
      @media #{$max-s} {
        font-size: 1.2rem;
        line-height: 1.3;
      }
    }
  }
}
.fadeIn {
  opacity: 0;
  animation: fadeIn 0.6s $cubic-out forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
}
